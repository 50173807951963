<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1621 1.97606C19.1621 2.63847 18.6416 3.15893 17.9792 3.15893H3.59549C2.93308 3.15893 2.41262 3.67939 2.41262 4.3418V25.6335C2.41262 26.2959 2.93308 26.8163 3.59549 26.8163H20.061C20.7234 26.8163 21.2439 26.2959 21.2439 25.6335V10.7293C21.2439 10.0669 21.7644 9.54643 22.4268 9.54643C23.0892 9.54643 23.6097 10.0669 23.6097 10.7293V25.6335C23.6097 27.5734 22.0009 29.1821 20.061 29.1821H3.59549C1.65558 29.1821 0.046875 27.5734 0.046875 25.6335V4.3418C0.046875 2.40189 1.65558 0.793189 3.59549 0.793189H17.9792C18.6416 0.793189 19.1621 1.31365 19.1621 1.97606ZM4.96762 9.07328H11.0712C11.7336 9.07328 12.2541 8.55282 12.2541 7.89041C12.2541 7.228 11.7336 6.70754 11.0712 6.70754H4.96762C4.30521 6.70754 3.78474 7.228 3.78474 7.89041C3.78474 8.55282 4.30521 9.07328 4.96762 9.07328ZM4.96762 14.1833H11.0712C11.7336 14.1833 12.2541 13.6628 12.2541 13.0004C12.2541 12.338 11.7336 11.8175 11.0712 11.8175H4.96762C4.30521 11.8175 3.78474 12.338 3.78474 13.0004C3.78474 13.6628 4.30521 14.1833 4.96762 14.1833ZM18.8782 16.5963H4.77836C4.11595 16.5963 3.59549 17.1168 3.59549 17.7792C3.59549 18.4416 4.11595 18.9621 4.77836 18.9621H18.8782C19.5406 18.9621 20.061 18.4416 20.061 17.7792C20.061 17.1168 19.5406 16.5963 18.8782 16.5963ZM18.8782 21.2805H4.77836C4.11595 21.2805 3.59549 21.801 3.59549 22.4634C3.59549 23.1258 4.11595 23.6462 4.77836 23.6462H18.8782C19.5406 23.6462 20.061 23.1258 20.061 22.4634C20.061 21.801 19.5406 21.2805 18.8782 21.2805ZM25.6442 1.12439C25.171 0.651244 24.414 0.651244 23.9882 1.12439L15.5188 9.54643C15.0457 10.0196 15.0457 10.7766 15.5188 11.2024C15.7554 11.439 16.0393 11.5337 16.3705 11.5337C16.6544 11.5337 16.9856 11.439 17.2222 11.2024L25.6442 2.82773C26.1173 2.35458 26.1173 1.59754 25.6442 1.12439Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "EarningIcon",
};
</script>