<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM6.765 5.015C6.69 5.09 6.595 5.125 6.5 5.125C6.405 5.125 6.31 5.09 6.235 5.015L5.375 4.155V6.75C5.375 6.955 5.205 7.125 5 7.125C4.795 7.125 4.625 6.955 4.625 6.75V4.155L3.765 5.015C3.62 5.16 3.38 5.16 3.235 5.015C3.09 4.87 3.09 4.63 3.235 4.485L4.735 2.985C4.88 2.84 5.12 2.84 5.265 2.985L6.765 4.485C6.91 4.63 6.91 4.87 6.765 5.015Z"
      fill="#66C27C"
    />
  </svg>
</template>
<script>
export default {
  name: "Arrowup",
};
</script>
