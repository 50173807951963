<template>
  <div class="hiring-card card px-2 pb-3">
    <div class="hiring-card__title d-flex justify-content-between mt-1 p-3">
      <h5 class="hiring-card__heading">Hiring Process</h5>
      <!-- <span class="hiring-card__link ml-2">
        Learn more
        <ArrowRight class="ml-2 d-none d-sm-inline" />
      </span> -->
    </div>
    <div class="hiring-card__body px-3 mt-3">
      <p>
        The onboarding process to join the Menaget pool of talents starts when you visit
        www.menaget.com and click on <strong>“Join Menaget”</strong> at the top right
        corner of the page. This allows you to create an account with Menaget. After
        creating an account, you then gain access to your dashboard. From the dashboard,
        complete the following process to join Menaget pool of talents. Navigate to the
        skill section to access the different talent skills categorized within different
        areas of specialization.
      </p>
      <p>
        You will go through a 3-stage assessment to confirm each skill that you have by
        selecting the skill from the list in the Skill pool.
      </p>
      <ol>
        <li>
          Confirm your years of experience to help assess you fairly and then click on the
          start test button.
        </li>
        <li>
          Start the Multiple Choice Question (MCQ) test.
          <ul>
            <li>
              If you are successful with the MCQ test, you will proceed to the next stage
              which is the coding test.
            </li>
          </ul>
        </li>
        <li>
          The coding test requires you to take a coding challenge.
          <ul>
            <li>
              You are expected to submit your solution within three (3) to five (5) days.
            </li>
            <li>
              Once your solution meets Menaget’s pass mark and above, then you can move to
              the next stage which is to schedule an interview.
            </li>
          </ul>
        </li>
        <li>
          Schedule an interview with our internal team.
          <ul>
            <li>
              Select the date and time for the interview and the team will contact you.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        After a successful interview, you will officially be added to the Menaget pool of
        talents where you will get placements to top job opportunities from around the
        world.
      </p>
    </div>
  </div>
</template>
<script>
//import ArrowRight from "../svg/ArrowRight.vue";
export default {
  name: "HiringCard",
  components: {
    //ArrowRight
  },
};
</script>
<style lang="scss" scoped>
.hiring-card {
  color: $dark-blue;
  border-radius: 10px 10px 0 0;
  @include media-breakpoint-down(xs) {
    font-size: 0.875rem;
  }
  &__title {
    background: $grey;
    color: $faint-blue;
    border-radius: 10px 10px 0 0;
  }
  &__heading {
    font-size: 20px;
    font-weight: 700;
    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }
  &__link {
    font-size: 14px;
  }
}
</style>
