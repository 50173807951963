<template>
  <div class="dashboard-card-container card bg-light text-center">
    <div
      class="dashboard-card d-flex flex-column align-items-center justify-content-center text-dark-blue"
    >
      <h4 class="dashboard-card__title">Update your skills</h4>
      <p class="dasboard-card__text my-2">take our coding challenge</p>
      <div class="form-group w-100 mt-5">
        <b-button variant="primary" block class="m-large" @click="$emit('updated')">
          Update now
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UpdateSkills",
};
</script>
<style lang="scss" scoped>
.dashboard-card-container {
  padding: 3.5rem;
  border-radius: 15px;
  height: 100%;
  @include media-breakpoint-down(xs) {
    padding: 1.5rem;
  }
}
.dashboard-card {
  &__title {
    font-size: 1.7rem;
    font-weight: 800;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
  .m-large {
    font-size: 1.125rem;
    font-weight: 500;
  }
}
</style>
